import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button } from '@pledge-earth/product-language';

import { useAppSelector } from '../../store/hooks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useNavigateWithTestMode } from '../../hooks/useNavigateWithTestMode';
import { ClientSubscriptionStatusEnum } from '../../services/graphql/generated';

import './TrialCountDown.scss';

export function TrialCountDown({ mobile = false }: { mobile?: boolean }) {
  const { status, trial_end_date: trialEndDate } = useAppSelector((state) => state.user.subscription);
  const { client_chargebee_customer_card_status: cardStatus } = useAppSelector((state) => state.user);
  const isValidCard = cardStatus === 'valid';
  const shouldBeShownOnMobile = useMediaQuery('(max-width: 576px)');
  const navigateWithTestMode = useNavigateWithTestMode();

  // Check subscription status, the trial end date, and card status
  if (status !== ClientSubscriptionStatusEnum.InTrial || trialEndDate === null || isValidCard) {
    return null;
  }

  // Check mobile / desktop rule
  if (mobile !== shouldBeShownOnMobile) {
    return null;
  }

  const diffTime = new Date(trialEndDate).getTime() - new Date().getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  return (
    <div
      className={classNames('TrialCountDown', {
        'TrialCountDown--mobile': mobile,
      })}
      data-cy="trial-count-down"
    >
      <span data-cy="trial-count-down-days-left">
        {diffDays === 0 ? (
          <FormattedMessage id="trialCountDown.last_day" />
        ) : (
          <FormattedMessage id="trialCountDown.days_left" values={{ diffDays }} />
        )}
      </span>
      <Button
        variant="primary-alt"
        className="ml-2"
        onPress={() => {
          navigateWithTestMode('/plans');
        }}
      >
        <FormattedMessage id="trialCountDown.select_plan" />
      </Button>
    </div>
  );
}
