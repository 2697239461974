import { TrialCountDown } from '../TrialCountDown/TrialCountDown';

import { UserMenu } from './UserMenu/UserMenu';
import './TopBar.scss';

export function TopBar() {
  return (
    <div>
      <div className="TopBar">
        <TrialCountDown />
        <UserMenu />
      </div>
    </div>
  );
}
