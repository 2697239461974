import { Suspense, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { Layout } from '@pledge-earth/web-components';
import { Outlet } from 'react-router-dom';

import { Menu as MenuLeft } from '../../components/MenuLeft/Menu';
import { TopBar } from '../../components/TopBar/TopBar';
import { settingChanged } from '../../store/settings/reducers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useIsTestMode } from '../../hooks/useIsTestMode';
import { TestModeBar } from '../../components/TestModeBar/TestModeBar';
import { LoadingGeneric } from '../../components/Loading/LoadingGeneric';

import './MainLayout.scss';

const { Header, Content } = Layout;

let touchStartPrev = 0;
let touchStartLocked = false;

export function MainLayout(props: { testModeSensitive: boolean }) {
  const dispatch = useAppDispatch();
  const isMobileMenuOpen = useAppSelector((state) => state.settings.isMobileMenuOpen);
  const testModeEnabled = useIsTestMode();

  // Show Test Mode Bar
  const showTestModeBar = testModeEnabled && props.testModeSensitive;

  // touch slide mobile menu opener
  const toggleMobileMenu = () => {
    dispatch(
      settingChanged({
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      }),
    );
  };

  useEffect(() => {
    const unify = (e) => (e.changedTouches ? e.changedTouches[0] : e);
    document.addEventListener(
      'touchstart',
      (e) => {
        const x = unify(e).clientX;
        touchStartPrev = x;
        touchStartLocked = x > 70;
      },
      { passive: false },
    );
    document.addEventListener(
      'touchmove',
      (e) => {
        const x = unify(e).clientX;
        const prev = touchStartPrev;
        if (x - prev > 50 && !touchStartLocked) {
          toggleMobileMenu();
          touchStartLocked = true;
        }
      },
      { passive: false },
    );
  });

  return (
    <Layout className="MainLayout">
      <MenuLeft />
      <Layout className="MainLayout__right">
        <Header className="MainLayout__header">
          <TopBar />
        </Header>
        {showTestModeBar ? <TestModeBar /> : null}
        <Content className="MainLayout__content">
          <div className="flex min-w-0 flex-wrap">
            <div className="relative min-h-px max-w-full shrink-0 grow-0 basis-full">
              <Suspense fallback={<LoadingGeneric delayMs={300} />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
