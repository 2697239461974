import { FormattedMessage } from 'react-intl';
import { Text, Heading, Paragraph } from '@pledge-earth/product-language';

import type { MessageId } from '../../../locales/types';
import '../AuthContent.scss';

export enum ErrorCode {
  LINK_INVALID = 'LINK_INVALID',
  LINK_INVALID_NO_EMAIL = 'LINK_INVALID_NO_EMAIL',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  CODE_EXPIRED = 'CODE_EXPIRED',
  CODE_INVALID = 'CODE_INVALID',
  SESSION_MISSING = 'SESSION_MISSING',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  RESEND_ERROR = 'RESEND_ERROR',
}

const NO_RESEND_ERRORS = [
  ErrorCode.RESEND_ERROR,
  ErrorCode.LINK_INVALID_NO_EMAIL,
  ErrorCode.ERROR_UNKNOWN,
  ErrorCode.SESSION_MISSING,
] as const;

const ERROR_CODE_TO_TRANSLATION: { [key in ErrorCode]: MessageId } = {
  LINK_INVALID: 'auth.confirm_sign_up_error.code_invalid', // malformed link (missing or empty parameters)
  SESSION_EXPIRED: 'auth.confirm_sign_up_error.code_expired', // cognito returned session expired
  CODE_EXPIRED: 'auth.confirm_sign_up_error.code_expired', // cognito returned code expired
  CODE_INVALID: 'auth.confirm_sign_up_error.code_invalid', // cognito returned code invalid
  SESSION_MISSING: 'auth.confirm_sign_up_error.session_missing', // no session in local storage
  ERROR_UNKNOWN: 'auth.confirm_sign_up_error.error_unknown',
  RESEND_ERROR: 'auth.confirm_sign_up_error.send_error',
  LINK_INVALID_NO_EMAIL: 'auth.confirm_sign_up_error.link_invalid_no_email',
};

export function ConfirmSignUpError({
  sendEmail,
  emailResent,
  errorCode,
}: {
  sendEmail: () => void;
  emailResent?: boolean;
  errorCode?: ErrorCode;
}) {
  const errorTranslation = ERROR_CODE_TO_TRANSLATION[errorCode || ErrorCode.ERROR_UNKNOWN];

  return (
    <div className="Auth__layout">
      <div className="flex min-w-0 flex-wrap">
        <div className="relative min-h-px max-w-full shrink-0 grow-0 basis-full">
          <div className="AuthContent flex flex-col gap-0">
            <Heading level="h2">
              <FormattedMessage id="auth.confirm_sign_up_error.title" />
            </Heading>
            {emailResent && errorCode !== ErrorCode.RESEND_ERROR ? (
              <div className="AuthContent__info-box">
                <Text>
                  <FormattedMessage id="auth.self_sign_up.verify_your_email.resent" />
                </Text>
              </div>
            ) : (
              <>
                <Paragraph>
                  <Text>
                    <FormattedMessage
                      id={errorTranslation}
                      values={{
                        support: <a href="mailto: support@pledge.io">support@pledge.io</a>,
                      }}
                    />
                  </Text>
                </Paragraph>
                {errorCode === ErrorCode.SESSION_MISSING && (
                  <Paragraph>
                    <Text>
                      <FormattedMessage id="auth.confirm_sign_up_error.session_missing.incognito" />
                    </Text>
                  </Paragraph>
                )}
              </>
            )}
            {(errorCode && (NO_RESEND_ERRORS as readonly ErrorCode[]).includes(errorCode)) || emailResent ? null : (
              <Paragraph>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- eslint onboarding */}
                <a
                  className="AuthContent__link"
                  onClick={(event) => {
                    event.preventDefault();
                    sendEmail();
                  }}
                  aria-label="Send verification email"
                >
                  <FormattedMessage id="auth.confirm_sign_up_error.send_email" />
                </a>
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
